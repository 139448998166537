import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    sortBy: 'position',
    onlyActive: false,
    programsList: [],
  },
  getters: {
    getIsLoading: state => state.isLoading,
    getProgramsList: state => state.programsList,
    getSortBy: state => state.sortBy,
    getOnlyActive: state => state.onlyActive,
  },
  mutations: {
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    SET_PROGRAMS_LIST: (state, val) => {
      state.programsList = val
    },
    SET_SORT_BY: (state, val) => {
      state.sortBy = val
    },
    SET_ONLY_ACTIVE: (state, val) => {
      state.onlyActive = val
    },
  },
  actions: {
    async fetchProgramsList({ state, commit }) {
      try {
        commit('SET_IS_LOADING', true)
        const queryParams = {
          sortBy: state.sortBy ?? 'position',
          isActive: state.onlyActive,
        }

        const response = await axios.get('/auth/programs', {
          params: queryParams,
        })
        const programs = response.data.data.sort((a, b) => a.position - b.position)
        commit('SET_IS_LOADING', false)
        commit('SET_PROGRAMS_LIST', programs)
        return response
      } catch (error) {
        return error
      }
    },
    async saveProgramsPositions({ state, commit }, vm) {
      try {
        commit('SET_IS_LOADING', true)
        const queryParams = {
          ids: state.programsList.map(item => item.id),
        }
        const response = await axios.post('/auth/programs/update/positions', queryParams)
        commit('SET_IS_LOADING', false)
        return response
      } catch (error) {
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong, please refresh the page',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      }
    },
  },
}
