


















































































































import Vue from 'vue'
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardFooter,
  BCol,
  BListGroupItem,
  BOverlay,
  BRow,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { permissionSubjects } from '@/libs/acl/constants'
import store from '@/store'
import storeModule from '@/views/admin/settings-programs/settingsProgramsStoreModule'

export default Vue.extend({
  name: 'SettingsPrograms',
  components: {
    BButton,
    BFormCheckbox,
    BRow,
    BListGroupItem,
    BCol,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BOverlay,
    BCardTitle,
    BCard,

    vSelect,
    draggable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      permissionSubjects,
      sortByOptions: [
        { label: 'Position', value: 'position' },
        { label: 'Created Date', value: 'created_at' },
        { label: 'Start Date', value: 'date_start' },
        { label: 'Name', value: 'name' },
      ],
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-settings-programs'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  computed: {
    ...mapGetters({
      isLoading: 'app-settings-programs/getIsLoading',
      sortBy: 'app-settings-programs/getSortBy',
    }),
    programs: {
      get() {
        return store.getters['app-settings-programs/getProgramsList']
      },
      set(val) {
        store.commit('app-settings-programs/SET_PROGRAMS_LIST', val)
      },
    },
    onlyActive: {
      get() {
        return store.getters['app-settings-programs/getOnlyActive']
      },
      set(val) {
        store.commit('app-settings-programs/SET_ONLY_ACTIVE', val)
      },
    },
  },
  watch: {
    sortBy(val, prevVal) {
      if (val !== prevVal) {
        this.prepareProgramsList()
      }
    },
    onlyActive(val, prevVal) {
      if (val !== prevVal) {
        this.prepareProgramsList()
      }
    },
  },
  async created() {
    await this.prepareProgramsList()
  },
  methods: {
    async prepareProgramsList() {
      await this.$store.dispatch('app-settings-programs/fetchProgramsList')
    },
    updatePerPage(val:any) {
      store.commit('app-settings-programs/SET_SORT_BY', val)
    },
    async saveOrdering() {
      await this.$store.dispatch('app-settings-programs/saveProgramsPositions')
    },
  },
})
